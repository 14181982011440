<template>
  <div class="notification pageInit">
    <h1 class="pageTitle textColor">Notifications</h1>
    <div class="filterContainer">
      <!--<el-select v-model="select" placeholder="">-->
      <!--  <el-option-->
      <!--      v-for="item in options"-->
      <!--      :key="item.value"-->
      <!--      :label="item.label"-->
      <!--      :value="item.value"-->
      <!--  >-->
      <!--  </el-option>-->
      <!--</el-select>-->
      <el-row class="searchContiner">
        <el-col :xs="24" :md="8" class="notificationTabs">
          <router-link to="/notification" class="x-btn">NOTIFICATIONS</router-link>
          <!--<router-link to="/polls" class="x-btn">POLLS</router-link>-->
          <router-link to="/survey" class="x-btn">SURVEY</router-link>
        </el-col>
        <el-col :xs="24" :md="8" style="display: flex;justify-content: flex-end;margin-bottom: 15px">
          <searchBox style="margin-right: 20px"
                     :show-go="true"
                     @clickGoBtn="clickGoBtn"
                     ref="refSearch"
          ></searchBox>
        </el-col>
      </el-row>
    </div>

    <div class="lists">
      <div class="list" v-for="(item, index) in lists" :key="'list' + index" @click="goNotification(item)">
        <div class="listContainer">
          <!--<p class="docIcon">-->
          <!--  <img v-if="item.img" :src="item.img" alt=""/>-->
          <!--  <i v-else class="iconfont icon-pdf" style="font-size: 30px;color:gray"></i>-->
          <!--</p>-->
          <el-row :gutter="24" style="width: calc(100% + 30px);">
            <el-col :xs="24" :sm="20" :md="20" :lg="20" :xl="20">
              <div class="grid-content">
                <p class="docName">
                  <el-row :gutter="24" style="width: calc(100% + 30px);">
                    <el-col :xs="24" :sm="10" :md="10" :lg="10" :xl="10">
                      <el-row class="grid-content" style="display: flex;
                      align-items: center;flex-wrap: nowrap">
                        <el-row class="dot">
                          <span class="read" v-if="item.isRead==true"></span>
                          <span class="unread" v-if="item.isRead==false"></span>
                        </el-row>

                        <el-row class="subject">
                          <span class="title">{{ item.subject }}</span>
                          <br/>
                          <span class="sentDateTime">{{ item.sentDateTime }}</span>
                        </el-row>
                      </el-row>
                    </el-col>
                    <el-col :xs="24" :sm="14" :md="14" :lg="14" :xl="14" style="display: flex; align-items: center;">
                      <el-row class="message">
                        <span>{{ item.message }}</span>
                      </el-row>
                    </el-col>
                  </el-row>
                </p>
              </div>
            </el-col>
            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4"
                    style="display: flex; align-items: center;justify-content: center;">
              <div class="grid-content" style="">
                <p class="viewBtn">
                  <button class="x-btn" @click.stop="del(item.id)">Dismiss</button>
                </p>
              </div>
            </el-col>
          </el-row>


        </div>
      </div>
    </div>
    <div class="paginationContainer">
      <el-pagination
          v-model:currentPage="currentPage"
          v-model:page-size="pageSize"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          background
          :page-sizes="[5, 10, 15, 20,50]"
          layout="prev, pager, next"
          :total="total"
          class="pagination mt-4"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
  ref,
  toRaw,
} from "vue";
import searchBox from "@/components/tools/search.vue";
import axios from "@/api/http";
import {useStore} from 'vuex';
import {ElMessage, ElMessageBox} from "element-plus";
import {ElLoading} from 'element-plus';
import notification from "@/store/notification";
import {useRouter} from "vue-router";

export default defineComponent({
  name: "Notification",
  components: {
    searchBox,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const refSearch = ref(null);//用来查询的时候获取searhBox组件的input的值
    const data = reactive({
      // 分页
      currentPage: 1,
      pageSize: 5,
      total: 0,
      select: '',
      options: [
        {label: "All Tags", value: 1},
      ],
      lists: [
        // {
        //   img: require("../../assets/imgs/icon.jpg"),
        //   name: "Virtual Vendor show overview",
        //   id: 1,
        // },
        // {
        //   img: require("../../assets/imgs/icon.jpg"),
        //   name: "MC Event Tutoral",
        //   id: 1,
        // },
        // {img: require("../../assets/imgs/icon.jpg"), name: "Lorem", id: 1},
      ],
    });
    const formatData = (data) => {
      let arr = [];
      data.map(item => {
        let obj = {};
        obj.img = item.img;
        obj.name = item.subject;
        obj.id = item.id;
        obj.link = item.link;
        arr.push(obj);
      })
      return arr
    };
    const getData = (keyword, pageSize, index) => {
      let refKeyword = refSearch.value.input1.trim();
      const loading = ElLoading.service({
        lock: true,
      })
      axios.request({
        method: 'PATCH',
        url: '/virtual/notification',
        data: {
          meetingId: 'a7yyxczuty0qpjo2393gmyad',
          pageSize: pageSize || data.pageSize,
          index: index || data.currentPage - 1,
          keyword: keyword || refKeyword
          // meetingId: localStorage.eventsMeetingId,
        }
      }).then(res => {
        // data.lists = formatData(res.data.data);
        data.total = res.data.total;
        data.lists = res.data.data;
        let isChange = store.state.notification.notificationChange;
        store.commit("setNotificationChange", !isChange);
        loading.close();
      }).catch(err => {
        loading.close();
        console.log(err);
      })
    }
    const handleSizeChange = (page) => {
      getData();
    }
    const handleCurrentChange = () => {
      getData();
    }
    // const goNotification = (i) => {
    //   // console.log(i);
    //  const loading = ElLoading.service({
    //     lock: true,
    //   })
    //
    //
    //   axios.request({
    //     method: 'put',
    //     url: '/virtual/isReadNotification',
    //     data: {
    //       meetingId: 'mg9lr5rzd62to2hcidef11id',
    //       id: i.id
    //       // meetingId: localStorage.eventsMeetingId,
    //     }
    //   }).then(res => {
    //     // console.log(res)
    //     if(res.data.data == 'success'){
    //       loading.close();
    //       console.log("====i =====",i.linkType)
    //       if(i.linkType=='p_notifications_polls'){
    //         router.push({
    //           name: 'Polls',
    //           query: {id: i.linkTo},
    //         });
    //       }
    //       // ElMessage({
    //       //   type: 'success',
    //       //   message: 'success'
    //       // });
    //       getData()
    //     }
    //     // getData();
    //   }).catch(err => {
    //     console.log(err);
    //   })
    // }
    const goNotification = (i) => {
      console.log("==== 2222=====",)
    }
    const del = (id) => {
      ElMessageBox.confirm(
          'Are you sure you want to remove this notification?.',
          'Think Twice',
          {
            confirmButtonText: 'Remove',
            cancelButtonText: 'Cancel',
            type: 'warning',
            closeOnClickModal:false
          }
      ).then(()=>{
        const loading = ElLoading.service({
          lock: true,
        })
        axios.request({
          method: 'put',
          url: '/virtual/dissmissNotification',
          data: {
            meetingId: 'mg9lr5rzd62to2hcidef11id',
            id: id
            // meetingId: localStorage.eventsMeetingId,
          }
        }).then(res => {
          if (res.data.data == 'success') {
            loading.close();
            let length = data.lists.length;
            for(let i = 0; i < length; i++){
              let item = data.lists[i];
              if(id==item.id){
                data.lists.splice(i,1);
                break
              }
            }
            // getData()
          }
        }).catch(err => {
          console.log(err);
        })
      })

    }
    const clickGoBtn = (keyword) => {
      data.currentPage = 1;
      getData(keyword)
    }
    onBeforeMount(() => {
    });
    onMounted(() => {
      getData();
    });
    return {
      ...toRefs(data),
      store,
      router,
      refSearch,
      getData,
      goNotification,
      del,
      handleSizeChange,
      handleCurrentChange,
      clickGoBtn
    };
  },
});
</script>
<style scoped lang='scss'>
.notification {
  //表格分页
  .paginationContainer {
    margin: 30px auto;

    .pagination {
      display: flex;
      justify-content: center;
    }
  }

  .filterContainer {
    //display: flex;
    //align-items: center;
    //justify-content: flex-end;

    .searchContiner {
      display: flex;
      align-items: center;
      margin-right: 15px;
    }
  }

  .notificationTabs {
    text-align: center;
    margin: 0 0 30px auto;

    a {
      margin: 0 10px;
      cursor: pointer;
      text-decoration: none;
      display: inline-block;
    }
  }

  .lists {
    padding: 0px 0 20px 0;
    border-top: 1px solid #fff;

    .list:nth-child(odd) {
      //background: #f2f3f5;
      background: #e8f5f9;
    }

    .list:nth-child(even) {
      background: #fff;
    }

    .list {
      cursor: pointer;

      .listContainer {
        display: flex;
        align-items: center;
        padding: 15px;
        min-height: 40px;

        .docIcon {
          width: 200px;
          text-align: center;
        }

        .docName {
          flex: 1;
          font-size: 14px;
          margin-bottom: 10px;
          /* display: flex;
          align-items: center; */
          div {
            /* display:inline-block; */
          }

          .subject {
            padding: 0 20px;
          }

          .read {
            display: inline-block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #B2BECA;
          }

          .unread {
            display: inline-block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #97D700;
          }

          .title {

          }

          .sentDateTime {
            /* padding: 0 10px; */
          }
        }

        .viewBtn {
          width: auto;

          button {
            background-color: #2dccd3;
            min-width: 120px;
            //font-size:1rem;
            border: 0;
            border-radius: 15px;
            height: 30px;
            font-family: OpenSans-Semibold;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
